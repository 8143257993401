/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

 define([
    'jquery',
    'mage/smart-keyboard-handler',
    'mage/mage',
    'domReady!'
], function ($, keyboardHandler) {
    'use strict';

    $('.cart-summary').mage('sticky', {
        container: '#maincontent'
    });

    $('.panel.header > .header.links').clone().appendTo('#store\\.links');
    $('#store\\.links li a').each(function () {
        var id = $(this).attr('id');

        if (id !== undefined) {
            $(this).attr('id', id + '_mobile');
        }
    });

    keyboardHandler.apply();

    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 300) {
            jQuery('#back_top').fadeIn();
        } else {
            jQuery('#back_top').fadeOut();
        }
    });
    jQuery("#back_top").click(function() {
        jQuery("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });
     $('ul.download-center-category li a').click(function(){
         var activeLink = $(this).attr("class");
         if (!$(this).hasClass("active")) {
             $(this).addClass('active').parents().siblings().children().removeClass('active');
             $('.pagebuilder-column.pdf-container-column .'+activeLink).addClass('active').siblings().removeClass('active');
         }
     });
     $('.next-load.load-more-btn.pm-ajax-button').click(function(){
         $('#amasty-shopby-product-list').addClass('more-btn-loading');

     });
     $(document).ready(function() {
         $('.price-box.price-final_price').addClass('swatch-option-loading');
         $('.product-item .product-item-info .product-item-details .price-box.price-final_price').css('visibility', 'visible');
         const myTimeout = setTimeout(removePriceLoader, 3000);
         function removePriceLoader() {
             $('.price-box.price-final_price').removeClass('swatch-option-loading');
         }
         $(window).scroll(function() {    
            var scroll = $(window).scrollTop();
            if (scroll >= 40) {
                $(".page-header").addClass("sticky-header-block");
            }else{
                $(".page-header").removeClass("sticky-header-block");
            }
        }); 
     });

    $(document).ready(function() {
        jQuery('ul.nav-desktop').children("li").last().children("a").attr("onclick", "gtag('event','conversion', {'send_to':'AW-11054882942/xZAKCLmSgocYEP7AsJcp'});gtag('event','CustomizeProduct');fbq('track','CustomizeProduct');");
    });
    
});
